import React from "react"

import Layout from "../components/layout"

import './materialize.css'
import './theme.css'
import './helpers.css'

//import homepage from
import Home from "../pages/Home";

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
)

export default IndexPage
